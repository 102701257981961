export default {
  public: [],
  admin: [
    // store列表
    {
      path: 'store',
      name: 'store',
      component: () => import('@/modules/store/views/storeList.vue'),
    },

    // store表單
    {
      path: 'store/create',
      name: 'store-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/store/views/storeForm.vue'),
    },
    {
      path: 'store/update/:target',
      name: 'store-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/store/views/storeForm.vue'),
    },
  ],
}
